import React from "react";
import ft_trancendence from '../../assets/ft_trancendence.png'
import inception from '../../assets/inception.webp'
import cub from '../../assets/cub.png'
import anegma from '../../assets/anegma.png'
import './Work.css'

function Work()
{
    return (
        <div className="my-work">
            <div className="work">
                <div className="info">
                    <div className="desc">
                            <div className="title">my work</div>
                            <p>welcome to my projects showcase</p>
                    </div>
                    <a href="https://github.com/YassineAjallal?tab=repositories" target="_blank" rel="noreferrer">
                            <button id="view-all">view all</button>
                    </a>
                </div>
                <div className="projects">
                    <div className="project lg-column">
                            <img src={ft_trancendence} alt="" />
                            <div className="desc">
                                <h4>ft_trancendence</h4>
                                <button disabled id="disabled">in progress</button>
                            </div>

                    </div>
                    <div className="project md-column">
                            <img src={inception} alt="" />
                            <div className="desc">
                                <h4>inception</h4>
                                <a href="https://github.com/YassineAjallal/inception_42" target="_blank" rel="noreferrer">
                                        <button>link</button>
                                </a>
                            </div>

                    </div>
                    <div className="project md-column">
                            <img src={cub} alt="" />
                            <div className="desc">
                                <h4>cub 3d</h4>
                                <a href="https://github.com/YassineAjallal/cub3d_42" target="_blank" rel="noreferrer">
                                        <button>link</button>
                                </a>
                            </div>

                    </div>
                    <div className="project lg-column">
                            <img src={anegma} alt="" />
                            <div className="desc">
                                <h4>anegma</h4>
                                <a href="https://anegma.ma" target="_blank" rel="noreferrer">
                                        <button>link</button>
                                </a>
                            </div>

                    </div>
                </div>
            </div>
         </div>
    )
}

export default Work