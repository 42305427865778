import React from "react";
import { useState } from "react";
import { useFormik } from 'formik';

import './Contact.css'


const validate = values => {

    const errors = {};
 
    if (!values.name)
      errors.name = 'Required';
    else if (values.name.length > 25)
      errors.name = 'Must be 25 characters or less';

    if (!values.message)
      errors.message = 'Required';
 
    if (!values.email)
      errors.email = 'Required';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid email address';
 
    return errors; 
};

function Contact ()
{
    const [isLoading, setIsLoading] = useState(false)
    const formik = useFormik({

        initialValues: {
          name: '',
          message: '',
          email: '',
        },
        validate,
   
        onSubmit: values => {
            setIsLoading(true)
            var data = {
                service_id: process.env.REACT_APP_SERVICE_ID,
                template_id: process.env.REACT_APP_TEMPLATE_ID,
                user_id: process.env.REACT_APP_USER_ID,
                template_params: values
            };
            fetch('https://api.emailjs.com/api/v1.0/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if (response.ok)
                    setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            });
        },
      });
    return (
        <div className="contact" id="contact">
            <div className="title">contact</div>
            <div className="contact-info">
                <div className="contact-details">
                    <h2>contact info</h2>
                    <div id="contact-icons">
                        <p><i className="fas fa-map-marker-alt"></i>Tetouan</p>
                        <p><i className="fas fa-mobile-alt"></i>+212620443239</p>
                        <p><i className="far fa-envelope"></i>yassineajallal@gmail.com</p>
                    </div>
                    <h2>follow me</h2>
                    <div id="sm-icons">
                        <a href="https://github.com/YassineAjallal" target="_blank" rel="noreferrer"><i className="fab fa-github" ></i></a>
                        <a href="https://www.linkedin.com/in/yassine-ajallal-aa5220290/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                        <a href="https://x.com/YassineAjallal?t=cBniB1ef9OjHSMETKKd_VA&s=09" target="_blank" rel="noreferrer"><i class="fa-brands fa-x-twitter"></i></a>

                    </div>
                </div>
                <form method="post" className="contact-form" onSubmit={formik.handleSubmit}>
                    <div>
                        <label htmlFor="name">name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}

                        />
                        {formik.errors.name ? <div className="error-message">{formik.errors.name}</div> : null}
                    </div>
                    <div>
                        <label htmlFor="email">email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}

                        />
                        {formik.errors.email ? <div className="error-message">{formik.errors.email}</div> : null}
                    </div>
                    <div>
                        <label htmlFor="message">your message</label>
                        <textarea
                            name="message"
                            id="message"
                            rows="10"
                            onChange={formik.handleChange}
                            value={formik.values.message}>
                        </textarea>
                        {formik.errors.message ? <div className="error-message">{formik.errors.message}</div> : null}


                    </div>
                    {
                        !isLoading ? <button id="send" type="submit">send</button> : <button className="button-load" disabled><span class="loader"></span></button>
                    }
                    
                </form>
            </div>
        </div>
    )
}

export default Contact