import React from "react";
import yajallal from '../../assets/yajallal.jpg'
import { NavLink } from "react-router-dom";
import cv from '../../assets/final_resume.pdf'
import './About.css'

function About()
{
    return (
        <div className="about" id="about">
            <div className="about-me">
                <div className="image-section">
                        <div className="image">
                            <img src={yajallal} alt="yajallal" />
                        </div>
                </div>
                <div className="description-section">
                        <h1 className="title">about me</h1>
                        <p>
                            Analytical and passionate Programmer offering two years of
                            experience in code creation and software development. Skilled in
                            multiple front-end and back-end programming languages,
                            including HTML, CSS, JavaScript, Python, and Django. 
                        </p>
                        <div className="btns">
                            <NavLink to="/work"><button id="work-btn">My work</button></NavLink>
                            <a href={cv} download><button id="cv-btn">download cv</button></a>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default About