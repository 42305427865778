import React from "react";
import './Home.css'
import { NavLink } from "react-router-dom";


function Home()
{
    return (
        <div className="home" id="home">
            <div className="main-section">
                <div className="introduce disposition"><p>hi i'am</p><span></span></div>
                <div className="big-name">yassine</div>
                <div className="job disposition"><span></span><p>back end developer</p></div>
            </div>
            <div className="icons">
                <div className="social-media">
                    <a href="https://github.com/YassineAjallal" target="_blank" rel="noreferrer"><i className="fab fa-github" ></i></a>
                    <a href="https://www.linkedin.com/in/yassine-ajallal-aa5220290/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href="https://x.com/YassineAjallal?t=cBniB1ef9OjHSMETKKd_VA&s=09" target="_blank" rel="noreferrer"><i class="fa-brands fa-x-twitter"></i></a>
                </div>
                <div className="see-my-work">
                    <NavLink to="/work">
                        <div className="work">
                            <p>see my work</p><i className="fas fa-arrow-right"></i>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Home