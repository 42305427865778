import React from "react"
import { NavLink } from "react-router-dom"
import './Navbar.css'


const classNameFunc = ({ isActive }) => (isActive ? "active-link" : "");

function Navbar()
{
    const openMenuHandler = () =>
    {
        document.getElementById("mobile-links").style.display = "block"
        document.body.style.overflow = "hidden"
    }

    const closeMenuHandler = () =>
    {

        document.getElementById("mobile-links").style.display = "none"
        document.body.style.overflow = "visible"
    }

    return (
        <div className="navbar">
            <NavLink to="/" className={classNameFunc}>
                    <div className="logo">
                        <h2>ya</h2>
                    </div>
            </NavLink>
            <div className="links">
                    <ul>
                        <NavLink className={classNameFunc} to="/"><li>home</li></NavLink>
                        <NavLink className={classNameFunc} to="/about"><li>about</li></NavLink>
                        <NavLink className={classNameFunc} to="/work"><li>my work</li></NavLink>
                        <NavLink className={classNameFunc} to="/skills"><li>skills</li></NavLink>
                        <NavLink className={classNameFunc} to="/contact"><li>contact</li></NavLink>
                        
                    </ul>
            </div>
            <div className="menu" id="menu" onClick={openMenuHandler}>
                <i className="fa-solid fa-bars"></i>
            </div>
            <div className="mobile-links" id="mobile-links">
                <div className="close" id="close"><i className="fa-solid fa-x" onClick={closeMenuHandler}></i></div>
                <ul>
                    <NavLink onClick={closeMenuHandler} to="/"><li>home</li></NavLink>
                    <NavLink onClick={closeMenuHandler} to="/about"><li>about</li></NavLink>
                    <NavLink onClick={closeMenuHandler} to="/work"><li>my work</li></NavLink>
                    <NavLink onClick={closeMenuHandler} to="/skills"><li>skills</li></NavLink>
                    <NavLink onClick={closeMenuHandler} to="/contact"><li>contact</li></NavLink>
                </ul>
            </div>
        </div>
    )
}

export default Navbar