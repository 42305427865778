import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Work from "./components/work/Work";
import Skills from "./components/skills/Skills";
import Contact from "./components/contact/Contact";
import './App.css'
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"

function App() {
  return (
    <div className="App container">
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/work' element={<Work />}/>
          <Route path='/skills' element={<Skills />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </div>
  );
}

export default App;
