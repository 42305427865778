import React from "react";
import './Skills.css'

function Skills()
{
    return (
        <div className="skills" id="skills">
            <div className="title">Skills</div>
            <div className="skills-details">
                <div className="skill-box">  
                        <i className="devicon-c-plain colored"></i>
                        <p>C</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-cplusplus-plain colored"></i>
                        <p>C++</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-html5-plain colored"></i>
                        <p>HTML5</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-css3-plain colored"></i>
                        <p>CSS3</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-javascript-plain colored"></i>
                        <p>JavaScript</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-python-plain colored"></i>
                        <p>Python</p>
                </div><div className="skill-box">  
                        <i className="devicon-django-plain"></i>
                        <p>Django</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-postgresql-plain colored"></i>
                        <p>PostgreSQL</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-docker-plain colored"></i>
                        <p>Docker</p>
                </div>
                <div className="skill-box">  
                        <i className="devicon-git-plain colored"></i>
                        <p>Git</p>
                </div>
            </div>
        </div>
    )
}

export default Skills